/* @import url("https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;800&display=swap"); */

.App {
  text-align: center;
}

body {
  font-family: 'AndroclesOpti-Regular';
  margin: 0;
}

h2 {
  font-family: 'AndroclesOpti-Heavy';
}

input {
  font: 400 13.3333px 'AndroclesOpti-Regular';
}

a {
  font-family: 'AndroclesOpti-Heavy';
}
