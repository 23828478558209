.page {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo {
  text-align: center;
  margin: 50px 0;
  width: auto;
  height: 100px;
}
.text {
  white-space: pre-wrap;
  text-align: center;
  padding: 50px;
}
